:global .hero .has-background {
  position: relative;
  overflow: hidden;
  background-attachment: fixed;
}
.hero-background {
  position: absolute;
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: calc(100vh - 3.25rem); /* 100% !important; /* 'auto' can be used */
}
.hero-background.is-transparent {
  opacity: 0.5;
}
