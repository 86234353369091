@charset "utf-8";
@import "@fortawesome/fontawesome-free/css/all.css";

:root {
  --font-family: "Avenir-Medium", "Avenir", sans-serif;
  --bg_purple: #6152cc; //hsl(247, 54.5, 56.1);
  --design_bg: #ff88aa;
  --design_text: #c40233;
  --tech_bg: #ffd300;
  --tech_text: #000000;
  --user_text: #0087bd;
  --user_bg: #50e3c2;
  --super-pink: #fe57a1;
  --bg_orange: #F34826;
  --bg_soft_orange: #F4775E;
  --icon-green: #06A889;
  --bg-green: #CEEDE7;
  --ecoforma_red: #B52D05;
}

@media screen and (max-width:1023px) {
  :root {
    --title-font-size: 2rem;
    --subtitle-font-size: 1.2rem;
    --lead-font-size: 1rem;
    --small-text-font-size: 0.8rem;
  }

  :global .profile-pic {
    max-width: 30%;
  }
  
}

@media screen and (min-width:1024px) {
  :root {
    --title-font-size: 2.8rem;
    --subtitle-font-size: 1.5rem;
    --lead-font-size: 1.25rem;
    --small-text-font-size: 1rem;
  }

  :global .is-semi-transparent {
    background: linear-gradient(rgba(0,0,0,.6),rgba(0, 0, 0, 0.0)) !important;
  }

}

:global .is-semi-transparent {
  color: #ffe2e2; //#ffe2e2;
}

:global .lead {
  font-size: var(--lead-font-size);
  word-break: break-word;
  line-height: 1.5;
  font-weight: 400;
  color: inherit;
}

:global .super.title {
  font-size: var(--title-font-size) !important;
  font-weight: 600;
  line-height: 1.125;
  word-break: break-word;
  line-break: strict;
}

:global .super.subtitle {
  font-size: var(--subtitle-font-size) !important;
  font-weight: 400;
  line-height: 1.25;
  word-break: break-word;
  line-break: strict;
}

:global .small-text {
  font-size: var( --small-text-font-size );
  word-break: normal;
  line-height: 1;
  color: inherit;
}

:global .is-purple {
  background-color: var(--bg_purple, #6152cc); //hsl(247, 54.5, 56.1);
  border-color: transparent;
  color: #fff;
}

:global .button.is-purple:hover {
  color: hsl(247, 54.5, 30); //#ffe2e2;
  background-color: hsl(247, 54.5, 80); 
}

:global .is-orange {
  background-color: var(--ecoforma_red, #B52D05); //hsl(247, 54.5, 56.1);
  border-color: transparent;
  color: hsl(0, 0%, 96%);
}

:global .button.is-orange:hover {
  color: hsl(0, 0%, 96%);
  background-color: var(--bg_orange, #F34826);
  border-color: transparent;
}

:global .is-pink-underlined {
  background: linear-gradient(transparent 70%, #e66465, #9198e5);
}

:global .is-yellow-underlined {
  background: linear-gradient(transparent 70%, #0087bd, #ffffff, #000000, #0087bd);
}

:global .is-green-underlined {
  background: linear-gradient(transparent 70%,  #e66465, #ffd300, #0087bd, #000000);
}

:global .is-orange-underlined {
  background: var(--ecoforma_red, #B52D05);
  color: white;
}

:global .has-text-green {
  color: var(--icon-green, #06A889);
}

:global .navbar-link.is-active, :global .navbar-link:focus, :global .navbar-link:focus-within, :global .navbar-link:hover, :global a.navbar-item.is-active, :global a.navbar-item:focus, :global a.navbar-item:focus-within, :global a.navbar-item:hover {
  color: var(--icon-green, #06A889);
}

body {
  //font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-family: var(--font-family);
  ::selection {
    background: var(--bg-green, #CEEDE7);
    color: #000000;
    text-shadow: none;
}
  
}

//fix for ecoforma logo size
:global .navbar-burger {
  height: 4.25rem;
}

:global .navbar-item img {
  max-height: 3.75rem;
}

a {
  text-decoration: none;
}

img {
  margin-left: auto;
  margin-right: auto;
}

